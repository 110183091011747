import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useRef, forwardRef } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  Box,
  TableHead,
  CircularProgress,
  Divider,
  Paper,
  TextField,
  IconButton,
  Autocomplete
} from '@material-ui/core';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import { getUserList, deleteUser } from '../../redux/slices/user';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Page from '../../components/Page';
import LoadingScreen from 'src/components/LoadingScreen';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user/list';
import  ProductToolbar  from 'src/components/_dashboard/user/list/ProductToolbar.js';
import  ProductsSearch  from 'src/components/_dashboard/blog/ProductsSearch.js';
import  ProductsMenu  from 'src/components/_dashboard/user/list/ProductsMenu.js';
import axios from 'axios';
import {COMPANY_LOGO, COMPANY_NAME, REST_API, REST_API_END_POINT} from '../../constants/Defaultvalues';
import moment from 'moment';
import MaterialInventoryMenu from 'src/components/_dashboard/user/list/MaterialInventoryMenu';
import MaterialInventoryToolbar from 'src/components/_dashboard/user/list/MaterialInventoryToolbar';
import ReceiveOrderMenu from 'src/components/_dashboard/user/list/ReceiveOrderMenu';
import Modal from 'src/components/_dashboard/blog/ProjectModel';
import { useSnackbar } from 'notistack';
import { Delete, Print } from '@material-ui/icons';
import ReactToPrint from 'react-to-print';
import { styled } from '@material-ui/styles';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Order Name', alignRight: false },
  { id: 'vendor_name', label: 'Vendor Name', alignRight: false },
  { id: 'received_date', label: 'Date Received', alignRight: false },
  { id: 'expected_date', label: 'Date Expected', alignRight: false },
  { id: 'payment_mode', label: 'Payment Mode', alignRight: false },
  { id: 'payment_status', label: 'Payment Status', alignRight: false },
  // { id: 'addedby', label: 'Added By', alignRight: false },
  // { id: 'timestamp', label: 'Added On', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'options', label: '', alignRight: false},      
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const getStatusColorAndLabel = (status) => {
  switch (status) {
    case 0:
      return { color: 'warning', label: 'Order Approved' };
    case 1:
      return { color: 'info', label: 'Order Received' };
    case 2:
      return { color: 'success', label: 'Payment Completed' };
    default:
      return { color: 'warning', label: 'Status: Open' };
  }
};

const getPaymentMode = (mode) => {
  switch (mode) {
    case 1:
      return {label: 'Cash' };
    case 2:
      return {label: 'Check' }; // Cheque
    case 3:
      return {label: 'Debit Card' };
    case 4:
      return {label: 'Credit Card' };
    case 5:
      return {label: 'Consignment' };
    default:
      return { label:'' };
  }
};

const paymentTypes = [
  {id:1,  name: 'Cash'},
  {id:2,  name: 'Check'}, // Cheque
  {id:3,  name: 'Debit Card'},
  {id:4,  name: 'Credit Card'},
  {id:5,  name: 'Consignment'},
]


export default function ReceiveOrderList({clientId}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [orderBy, setOrderBy] = useState('id');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [ProductList, setProductList] = useState([]);
  const [viewOrder, setViewOrder] = useState(false);
  const [receivePreview, setReceivePreview] = useState(false);
  const [invoicePreview, setInvoicePreview] = useState(false);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [orderId, setOrderId] = useState();
  const [orderPreviewData, setOrderPreviewData] = useState([]);
  const [invoice, setInvoice] = useState(null);
  const location = useLocation();
  const receivedData = location.state?.data;
  const userData = JSON.parse(localStorage.getItem('user'))
  const userId = userData.user_id
  const userType = userData.user_type
  const explodedPermissions =userData?.exploded_permissions
  const { enqueueSnackbar } = useSnackbar();
  const componentRef = useRef();
  const [selectedPaymentMode, setSelectedPaymentMode] = useState(null);
  const [validSelection, setValidSelection] = useState(true);
  // const [rows, setRows] = useState([{ paymentMode: '', amount: 0 }]);


  // useEffect(() => {
  //   let total = 0;
  //   rows.forEach((row) => {
  //     total += parseFloat(row.amount);
  //   });
  //   if(total >= 0){
  //     setTotalAmount(total);
  //   }else{
  //     setTotalAmount(0);
  //   }
  // }, [rows]);

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);

  useEffect( async () => {
    try {
      const response = await axios.post(`${REST_API_END_POINT}product/get-all-receive-orders`,{userType,userId});
      if(response.data.status === 1){
        setProductList(response?.data?.orderData);
      }
      else{
        setProductList([]);
      }
    }
      catch (error) {
      console.error("Error fetching data:", error);
    }
    finally{
      setLoading(false);
    }
  }, [receivedData, refreshData]);

  const fetchOrderById = async () => {
    setLoading(true)
    try{
      const response = await axios.post(`${REST_API_END_POINT}product/get-order-by-id`,{orderId});
      if(response.data.status === 1){
        setOrderPreviewData(response.data.orderData)
        setInvoice(response.data.prodData)
      }
      else{
        setOrderPreviewData([])
      }
    }catch(error){
      console.log(error);
    }
    finally{
      setLoading(false)
    }
  }

  const LabelStyleHead = styled(Typography)(({ theme }) => ({
    ...theme.typography.h5,
    color: theme.palette.text.primary,
  }))

  useEffect(()=>{
    if(orderId){
      fetchOrderById()
    }
  },[orderId,receivePreview,invoicePreview,paymentOpen,viewOrder])

  // ...
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = slicedData?.map((n) => n.id);
      const newSelectedNames = slicedData?.map((n) => n.name);
      setSelected(newSelecteds);
      setSelectedNames(newSelectedNames);
      return;
    }
    setSelected([]);
    setSelectedNames([]);
  };

  const handleClick = (event, id,name) => {
    const selectedIndex = selected?.indexOf(id);
    const selectedNameIndex = selectedNames?.indexOf(name);
    let newSelected = [];
    let newselectedNames = [];
    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selected, id);
      newselectedNames = newselectedNames?.concat(selectedNames, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selected?.slice(1));
      newselectedNames = newselectedNames?.concat(selectedNames?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected?.concat(selected?.slice(0, -1));
      newselectedNames = newselectedNames?.concat(selectedNames?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(selected?.slice(0, selectedIndex), selected?.slice(selectedIndex + 1));
      newselectedNames = newselectedNames?.concat(selectedNames?.slice(0, selectedNameIndex), selectedNames?.slice(selectedNameIndex + 1));
    }
    setSelected(newSelected);
    setSelectedNames(newselectedNames);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSelected([]);
    setSelectedNames([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleDeleteUser = (userId) => {
    dispatch(deleteUser(userId));
  };

  const handleReceiveOrder = async () => {
    const data={};
    data['status'] = 1
    const response = await axios.post(`${REST_API_END_POINT}products/update-receive-order`,{orderId,data,userId})
    if(response.data.status === 1){
      enqueueSnackbar(`Order received successfully`, { variant: 'success' });
    }
    else{
      enqueueSnackbar(`Error receiving order`, { variant: 'error' });
    }
    setRefreshData(!refreshData)
    setReceivePreview(false)
  };

  const handleReceivePayment = async () => {
    if(selectedPaymentMode){
      const data={};
      data['payment_status'] = 1;
      data['status'] = 2
      data['payment_mode'] = selectedPaymentMode
      const response = await axios.post(`${REST_API_END_POINT}products/update-receive-order`,{orderId,data,userId})
      if(response.data.status === 1){
        enqueueSnackbar(`Payment submitted successfully`, { variant: 'success' });
      }
      else{
        enqueueSnackbar(`Error adding payment`, { variant: 'error' });
      }
      setRefreshData(!refreshData)
      setPaymentOpen(false)
      setSelectedPaymentMode(null)
      setValidSelection(true)
    }else{
      setValidSelection(false)
    }
  };

  const RowResultStyle = styled(TableRow)(({ theme }) => ({
    '& td': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  }))

  // const handleAddRow = () => {
  //   setRows([...rows, { paymentMode: null, amount: 0 }]);
  // };

  // const handleDeleteRow = (index) => {
  //   const updatedRows = [...rows];
  //   updatedRows.splice(index, 1);
  //   setRows(updatedRows);
  // };

  // const handlePaymentModeChange = (event, index) => {
  //   const updatedRows = [...rows];
  //   updatedRows[index].paymentMode = event.target.value;
  //   setRows(updatedRows);
  // };

  // const handleAmountChange = (event, index) => {
  //   const updatedRows = [...rows];
  //   updatedRows[index].amount = event.target.value;
  //   setRows(updatedRows);
  // }; ProductList.filter(item => item.qty > 0)
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - ProductList.length) : 0;
  const filteredUsers = applySortFilter((clientId ? ProductList?.filter(val=>val.vendor_id === clientId) : ProductList), getComparator(order, orderBy), filterName);
  const slicedData = filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const isUserNotFound = filteredUsers.length === 0;
  const invoiceDate = moment.unix(orderPreviewData[0]?.timestamp).format('MM/DD/YYYY')

  return (
      <Container>
        <Card>
          <MaterialInventoryToolbar 
            numSelected={selected?.length} 
            filterName={filterName} 
            onFilterName={handleFilterByName} 
            selectedName={selectedNames} 
            selected={selected} 
            onDelete={() => {setSelected([]);setPage(0);}}
            item="Received orders"
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
              <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={slicedData?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  showIdLabel='Order ID'
                  showId='id'
                />
                <TableBody>
                {loading ? (
                  <CircularProgress color="primary" variant="indeterminate" />
                ) : (
                  <>
                  {slicedData?.map((row) => {
                    let { id,name, timestamp, status, authorized_by, vendor_name, received_date, expected_date, payment_mode, payment_status } = row;
                    const isItemSelected = selected?.indexOf(id) !== -1;
                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell align='center'>
                          {/* <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id,name)} /> */}
                          {id}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap sx={{cursor:'pointer'}} onClick={()=>{setViewOrder(true);setOrderId(id);}}>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="center">{vendor_name}</TableCell>
                        <TableCell align="center">{moment.unix(received_date).format('MM/DD/YYYY')}</TableCell>
                        <TableCell align="center">{moment.unix(expected_date).format('MM/DD/YYYY')}</TableCell>
                        <TableCell align="center">{payment_mode && getPaymentMode(payment_mode).label}</TableCell>
                        <TableCell align="center">{(payment_status === 1) ? 'Paid' : 'Unpaid'}</TableCell>
                        {/* <TableCell align="center">{authorized_by}</TableCell>
                        <TableCell align="center">{moment.unix(timestamp).format('MM-DD-YYYY')}</TableCell> */}
                        <TableCell align="center">
                          <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            color={(status === 2) ? "success" : ((status === 1) ? "info" : "warning")}
                          >
                            {sentenceCase((status === 2) ? "Completed" : ((status === 1) ? "Received" : "Approved"))}
                          </Label>
                        </TableCell>

                        <TableCell align="center">
                        {clientId ? '' : userType == 3 ?
                        <ReceiveOrderMenu 
                          userName={name} 
                          id={id} 
                          userType={userType}
                          status={status} 
                          onRefresh={()=>{setRefreshData(!refreshData)}}  
                          onInvoice={(id)=>{setOrderId(id); setInvoicePreview(true);}}
                        />
                         :
                          <ReceiveOrderMenu 
                          onDelete={() => handleDeleteUser(id)} 
                          userName={name} 
                          id={id} 
                          status={status} 
                          onReceive={(id)=>{setOrderId(id); setReceivePreview(true);}} 
                          onRefresh={()=>{setRefreshData(!refreshData)}}  
                          onInvoice={(id)=>{setOrderId(id); setInvoicePreview(true);}}  
                          onPayment={(id)=>{setOrderId(id); setPaymentOpen(true);}}/>
                        }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                  </>
                    )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} msg="Receive orders"/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        {receivePreview && (
          <Modal
            open={receivePreview}
            handleClose={() => {
              setReceivePreview(false);
            }}
            modalTitle={orderPreviewData[0]?.order_status === 0 ? `Receive Order - ${orderPreviewData[0]?.order_name}` : `View Order - ${orderPreviewData[0]?.order_name}`}
          >
            {loading ? ( 
              <Stack alignItems='center' justifyContent='center'>
                <CircularProgress color="primary" variant="indeterminate" />
              </Stack>
             ) : (
              <>
                <Grid container sx={{ justifyContent: 'center' }} spacing={3}>
                  <Grid item xs={12} md={10}>
                    <Stack
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                      spacing={3}
                    >
                      <Stack
                        direction={{ xs: 'column', md: 'column' }} 
                        display="flex"
                        justifyContent="space-between"
                        spacing={3}
                      >
                        <Stack direction={{ xs: 'column', md: 'column' }}>
                        <Typography variant="body">
                        Order ID: <span style={{ color:theme.palette.primary.dark, fontWeight:'bold' }}>{orderPreviewData[0]?.order_id}</span>
                        </Typography>
                        <Typography variant="body">
                          Order Name: <span style={{ color:theme.palette.primary.dark, fontWeight:'bold' }}>{orderPreviewData[0]?.order_name}</span>
                        </Typography>
                        <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
                        <Label
                          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                          color={getStatusColorAndLabel(orderPreviewData[0]?.order_status).color} >
                          {getStatusColorAndLabel(orderPreviewData[0]?.order_status).label}
                        </Label>
                        </Grid>
                        </Stack>

                        {/* <Card> */}
                        <Scrollbar>
                          <TableContainer sx={{ maxWidth: '100%', border: '1px solid', borderColor: theme.palette.divider}}>
                            <Table>
                            <TableHead sx={{ width: '100%'}}>
                              <TableRow>
                                <TableCell align='center' sx={{ border: '1px solid',borderColor:theme.palette.divider }}>#</TableCell>
                                <TableCell align='center' sx={{ border: '1px solid',borderColor:theme.palette.divider }}>Product Name</TableCell>
                                <TableCell align='center' sx={{ border: '1px solid',borderColor:theme.palette.divider }}>Unit Cost</TableCell>
                                <TableCell align='center' sx={{ border: '1px solid',borderColor:theme.palette.divider }}>Quantity</TableCell>
                                <TableCell align='center' sx={{ border: '1px solid',borderColor:theme.palette.divider }}>Total</TableCell>
                              </TableRow>
                            </TableHead>
                              <TableBody>
                                {orderPreviewData?.map((row,index) => {
                                  let { order_name,order_id, vendor_name, product_name, unit_cost, actual_qty, actual_total_cost } = row;
                                  return (
                                    <TableRow
                                      hover
                                      key={index}
                                      tabIndex={-1}
                                    >
                                      <TableCell align='center' sx={{ border: '0.5px solid',borderColor:theme.palette.divider }}> {index+1} </TableCell>
                                      <TableCell align="center" sx={{ border: '0.5px solid',borderColor:theme.palette.divider }}> {product_name} </TableCell>
                                      <TableCell align="center" sx={{ border: '0.5px solid',borderColor:theme.palette.divider }}> ${unit_cost} </TableCell>
                                      <TableCell align="center" sx={{ border: '0.5px solid',borderColor:theme.palette.divider }}> {actual_qty} </TableCell>
                                      <TableCell align="center" sx={{ border: '0.5px solid',borderColor:theme.palette.divider }}> ${actual_total_cost} </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Scrollbar>
                        <Stack direction={{ xs: 'column', md: 'column' }}>
                        <Typography variant="subtitle2" sx={{mt:1}}>
                        Sub Total: <span style={{ color:theme.palette.primary.dark }}>${orderPreviewData[0]?.sub_total}</span>
                        </Typography>
                        <Typography variant="subtitle2" sx={{mt:1}}>
                          Tax: <span style={{ color:theme.palette.primary.dark }}>{orderPreviewData[0]?.tax}%</span>
                        </Typography>
                        <Typography variant="subtitle2" sx={{mt:1}}>
                          Grand Total: <span style={{ color:theme.palette.primary.dark }}>${orderPreviewData[0]?.grand_total}</span>
                        </Typography>
                        </Stack>
                        {/* </Card> */}
                        {orderPreviewData[0]?.order_status === 0 && <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap:2 }}>
                          <Button variant='contained' type='submit' onClick={handleReceiveOrder}>Submit</Button>
                          <Button variant='outlined' onClick={()=>{setReceivePreview(false); setSelectedPaymentMode(null); setValidSelection(true)}}>Cancel</Button>
                        </Box>}
                      </Stack> 

                    </Stack>
                  </Grid>
                </Grid>
              </>
            )}
          </Modal>
        )}

        {viewOrder && (
          <Modal
            open={viewOrder}
            handleClose={() => {
              setViewOrder(false);
            }}
            modalTitle={`View Order - ${orderPreviewData[0]?.order_name}`}
          >
            {loading ? ( 
              <Stack alignItems='center' justifyContent='center'>
                <CircularProgress color="primary" variant="indeterminate" />
              </Stack>
             ) : (
              <>
                <Grid container sx={{ justifyContent: 'center' }} spacing={3}>
                  <Grid item xs={12} md={10}>
                    <Stack
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                      spacing={3}
                    >
                      <Stack
                        direction={{ xs: 'column', md: 'column' }} 
                        display="flex"
                        justifyContent="space-between"
                        spacing={3}
                      >
                        <Stack direction={{ xs: 'column', md: 'column' }}>
                        <Typography variant="subtitle1">
                        Order ID : <span style={{ color:theme.palette.primary.dark, fontWeight:'bold' }}>{orderPreviewData[0]?.order_id}</span>
                        </Typography>
                        <Typography variant="subtitle1">
                          Order Name : <span style={{ color:theme.palette.primary.dark, fontWeight:'bold' }}>{orderPreviewData[0]?.order_name}</span>
                        </Typography>
                        <Typography variant="subtitle1">
                          Vendor : <span style={{ color:theme.palette.primary.dark, fontWeight:'bold' }}>{orderPreviewData[0]?.vendor_name}</span>
                        </Typography>
                        <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
                        <Label
                          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                          color={getStatusColorAndLabel(orderPreviewData[0]?.order_status).color} >
                          {getStatusColorAndLabel(orderPreviewData[0]?.order_status).label}
                        </Label>
                        </Grid>
                        </Stack>

                        {/* <Card> */}
                        <Scrollbar>
                          <TableContainer sx={{ maxWidth: '100%', border: '1px solid', borderColor: theme.palette.divider}}>
                            <Table>
                            <TableHead sx={{ width: '100%'}}>
                              <TableRow>
                                <TableCell align='center' sx={{ border: '1px solid',borderColor:theme.palette.divider }}>#</TableCell>
                                <TableCell align='center' sx={{ border: '1px solid',borderColor:theme.palette.divider }}>Product Name</TableCell>
                                <TableCell align='center' sx={{ border: '1px solid',borderColor:theme.palette.divider }}>Unit Cost</TableCell>
                                <TableCell align='center' sx={{ border: '1px solid',borderColor:theme.palette.divider }}>Quantity</TableCell>
                                <TableCell align='center' sx={{ border: '1px solid',borderColor:theme.palette.divider }}>Total</TableCell>
                              </TableRow>
                            </TableHead>
                              <TableBody>
                                {orderPreviewData?.map((row,index) => {
                                  let { order_name,order_id, vendor_name, product_name, unit_cost, actual_qty, actual_total_cost } = row;
                                  return (
                                    <TableRow
                                      hover
                                      key={index}
                                      tabIndex={-1}
                                    >
                                      <TableCell align='center' sx={{ border: '0.5px solid',borderColor:theme.palette.divider }}> {index+1} </TableCell>
                                      <TableCell align="center" sx={{ border: '0.5px solid',borderColor:theme.palette.divider }}> {product_name} </TableCell>
                                      <TableCell align="center" sx={{ border: '0.5px solid',borderColor:theme.palette.divider }}> ${unit_cost} </TableCell>
                                      <TableCell align="center" sx={{ border: '0.5px solid',borderColor:theme.palette.divider }}> {actual_qty} </TableCell>
                                      <TableCell align="center" sx={{ border: '0.5px solid',borderColor:theme.palette.divider }}> ${actual_total_cost} </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Scrollbar>
                        <Stack direction={{ xs: 'column', md: 'column' }}>
                        <Typography variant="subtitle2" sx={{mt:1}}>
                        Sub Total: <span style={{ color:theme.palette.primary.dark }}>${orderPreviewData[0]?.sub_total}</span>
                        </Typography>
                        <Typography variant="subtitle2" sx={{mt:1}}>
                          Tax: <span style={{ color:theme.palette.primary.dark }}>{orderPreviewData[0]?.tax}%</span>
                        </Typography>
                        <Typography variant="subtitle2" sx={{mt:1}}>
                          Grand Total: <span style={{ color:theme.palette.primary.dark }}>${orderPreviewData[0]?.grand_total}</span>
                        </Typography>
                        </Stack>
                        {/* </Card> */}
                      </Stack> 

                    </Stack>
                  </Grid>
                </Grid>
              </>
            )}
          </Modal>
        )}

        {invoicePreview && (
          <Modal
            open={invoicePreview}
            handleClose={() => {
              setInvoicePreview(false);
            }}
            modalTitle={`Invoice - ${orderPreviewData[0]?.order_name}`}
          >
          {(!orderPreviewData && !invoice) ?
            <Stack alignItems="center" justifyContent="center" style={{ height: '100%',width:'100%' }}>
              <CircularProgress color="primary" variant="indeterminate" />
            </Stack> 
            :
            <>
              <Stack direction={{ xs: 'column', sm: 'row', md: 'column' }}
                spacing={{ xs: 3, sm: 2 }}>
                <Stack direction="row" justifyContent={'flex-end'} alignItems="center" spacing={1}>
                  <ReactToPrint 
                    trigger={() =>(<Button> <Print />Print</Button>)}
                    content={() => componentRef.current}
                    copyStyles={false}
                    pageStyle={`
                      @media print {
                        body {
                          font-size: 12pt;
                        }
                        table {
                          width: 100%;
                          border-collapse: collapse;
                          margin-bottom: 10px;
                        }

                        th, td {
                          border: 1px solid #ddd;
                          padding: 8px;
                          text-align: center;
                        }
                        #right {
                          text-align: right;
                          margin-top: -200px; 
                        }
                        #total {
                          text-align: right;
                          display:flex;
                          justify-content: flex-end;
                        }
                        #subtotal {
                          text-align: left;
                          
                        }
                        #discount {
                          text-align: left;
                          word-wrap: break-word;
                          margin-right: 100px;
                      }
                      
                        #totalmain {
                          text-align: left;
                          
                        }
                        #subtotal1 {
                          text-align: left;
                          
                        }
                        #discount1 {
                          text-align: left;
                          word-wrap: break-word;
                          margin-right: 30px;
                      }
                      
                        #totalmain1 {
                          text-align: left;
                          
                        }
                        #total1 {
                          text-align: right;
                          display:flex;
                          justify-content: flex-end;
                          margin-top: -220px; 
                          
                        }
                        #order{
                          margin-bottom:-20px
                        }
                        #title{
                          margin-bottom:-20px
                        }
                        #orderID {
                          margin-bottom:-20px
                        }
                        #to {
                          margin-bottom:-20px
                        }
                        
                      }
                    `}
                  />
                  <div style={{ display: "none" }}>
                    <PrintFunction
                      invoiceDate={invoiceDate}
                      details={orderPreviewData[0]}
                      invoice={invoice}
                      ref={componentRef}
                    />          
                  </div>
                </Stack>
              </Stack>
              <Grid container spacing={3} >

                <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
                  <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
                <Box component="img" alt="logo" src={COMPANY_LOGO} sx={{ height: 98 }} />
              </Grid>
                  <Typography variant="body2">{COMPANY_NAME}</Typography>
                  <Typography variant="body2">www.signwisesolutions.com</Typography>
                  {/* <Typography variant="body2">1st Floor, Administrative block,
                    Kinfra Integrated Industrial Park,
                    Ottapalam, Palakkad, Kerala, India</Typography>
                  <Typography variant="body2">Telephone: +91-9496351674</Typography> */}
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mb: 5 }}>

                  <Typography variant="body2" sx={{marginBottom: '5px' }}>
                    Order date: {invoiceDate}
                  </Typography>
                  {/* <Stack sx={{display:'flex',flexDirection:'row'}}> */}
                  {/* <Typography variant="body2">Order status:</Typography> */}
                  <Label
                    variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                    color={getStatusColorAndLabel(orderPreviewData[0]?.order_status).color} >
                    {getStatusColorAndLabel(orderPreviewData[0]?.order_status).label}
                  </Label>
                  {/* </Stack> */}
                  <Typography variant="body2" sx={{marginTop: '5px' }}>Order Name : {orderPreviewData[0]?.order_name}</Typography>
                  <Typography variant="body2" sx={{marginTop: '5px' }}>Order ID : {orderPreviewData[0]?.order_id}</Typography>
                  <Typography variant="body2" sx={{marginTop: '5px' }}>Vendor Name : {orderPreviewData[0]?.vendor_name}</Typography>
                  <Typography variant="body2" sx={{marginTop: '5px' }}>{orderPreviewData[0]?.vendor_phone && 'Phone: +'+orderPreviewData[0]?.vendor_phone}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: '100%' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align='center'>#</TableCell>
                          <TableCell align='center'>PRODUCT</TableCell>
                          <TableCell align='center'>UNIT PRICE</TableCell>
                          <TableCell align='center'>QTY</TableCell>
                          <TableCell align='center'>SUB TOTAL</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {invoice?.map((val,index) => {
                          const { id, name, unit_cost, actual_qty, actual_total_cost,} = val              
                          return (

                            <TableRow key={id} sx={{
                              borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                            }}>
                              <TableCell align='center'>{index+1}</TableCell>
                              <TableCell align='center'>{name}</TableCell>
                              <TableCell align='center'>{unit_cost}</TableCell>
                              <TableCell align='center'>{actual_qty}</TableCell>
                              <TableCell align='center'>{actual_total_cost}</TableCell>
                            </TableRow>
                          )
                        }
                        )}

                        <RowResultStyle>
                          <TableCell colSpan={4} align='right'>
                            <Typography variant="overline">Subtotal</Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="overline">${orderPreviewData[0]?.sub_total}</Typography>
                          </TableCell>
                        </RowResultStyle>

                        <RowResultStyle>
                          <TableCell colSpan={4} align='right'>
                            <Typography variant="overline">Tax</Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="overline">{orderPreviewData[0]?.tax}%</Typography>
                          </TableCell>
                        </RowResultStyle>

                        <RowResultStyle>
                          <TableCell colSpan={4} align='right'>
                            <Typography variant="subtitle1">Grand Total</Typography>
                          </TableCell>
                          <TableCell align="left" >
                            <Typography variant="subtitle1">${orderPreviewData[0]?.grand_total}</Typography>
                          </TableCell>
                        </RowResultStyle>

                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                          
              </Grid>
            </>
          }
          </Modal>
        )}

        {paymentOpen && (
          <Modal
            open={paymentOpen}
            handleClose={() => {
              setPaymentOpen(false);
              setSelectedPaymentMode(null)
              setValidSelection(true)
            }}
            modalTitle={`Make Payment - ${orderPreviewData[0]?.order_name}`}
          >
            <Grid container sx={{ justifyContent: 'center' }} spacing={3}>
              <Grid item xs={12} md={10}>
                <Stack
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                  spacing={3}
                >
                  <Stack
                    direction={{ xs: 'column', md: 'column' }} 
                    display="flex"
                    justifyContent="space-between"
                    spacing={3}
                  >
                    <Stack direction={{ xs: 'column', md: 'column' }}>
                    <Typography variant="body">
                    Order ID: <span style={{ color:theme.palette.primary.dark, fontWeight:'bold' }}>{orderPreviewData[0]?.order_id}</span>
                    </Typography>
                    <Typography variant="body">
                      Order Name: <span style={{ color:theme.palette.primary.dark, fontWeight:'bold' }}>{orderPreviewData[0]?.order_name}</span>
                    </Typography>
                    </Stack>

                    {/* <Card> */}
                    <Scrollbar>
                      <TableContainer sx={{ maxWidth: '100%', border: '1px solid', borderColor: theme.palette.divider}}>
                        <Table>
                          <TableBody>
                          <TableRow sx={{backgroundColor:theme.palette.divider}}>
                            <TableCell align='center' sx={{ border: '1px solid',borderColor:theme.palette.divider }}>#</TableCell>
                            <TableCell align='center' sx={{ border: '1px solid',borderColor:theme.palette.divider }}>Product Name</TableCell>
                            <TableCell align='center' sx={{ border: '1px solid',borderColor:theme.palette.divider }}>Unit Cost</TableCell>
                            <TableCell align='center' sx={{ border: '1px solid',borderColor:theme.palette.divider }}>Quantity</TableCell>
                            <TableCell align='center' sx={{ border: '1px solid',borderColor:theme.palette.divider }}>Total</TableCell>
                          </TableRow>
                          {loading ? (
                            <CircularProgress color="primary" variant="indeterminate" />
                          ) : (
                            <>
                            {orderPreviewData?.map((row,index) => {
                              let { order_name,order_id, vendor_name, product_name, unit_cost, actual_qty, actual_total_cost } = row;
                              return (
                                <TableRow
                                  hover
                                  key={index}
                                  tabIndex={-1}
                                >
                                  <TableCell align='center' sx={{ border: '0.5px solid',borderColor:theme.palette.divider }}> {index+1} </TableCell>
                                  <TableCell align="center" sx={{ border: '0.5px solid',borderColor:theme.palette.divider }}> {product_name} </TableCell>
                                  <TableCell align="center" sx={{ border: '0.5px solid',borderColor:theme.palette.divider }}> ${unit_cost} </TableCell>
                                  <TableCell align="center" sx={{ border: '0.5px solid',borderColor:theme.palette.divider }}> {actual_qty} </TableCell>
                                  <TableCell align="center" sx={{ border: '0.5px solid',borderColor:theme.palette.divider }}> ${actual_total_cost} </TableCell>
                                </TableRow>
                              );
                            })}
                            </>
                          )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Scrollbar>
                    <Stack direction={{ xs: 'column', md: 'column' }}>
                    <Typography variant="subtitle2" sx={{mt:1}}>
                    Sub Total: <span style={{ color:theme.palette.primary.dark }}>${orderPreviewData[0]?.sub_total}</span>
                    </Typography>
                    <Typography variant="subtitle2" sx={{mt:1}}>
                      Tax: <span style={{ color:theme.palette.primary.dark }}>{orderPreviewData[0]?.tax}%</span>
                    </Typography>
                    <Typography variant="subtitle2" sx={{mt:1}}>
                      Grand Total: <span style={{ color:theme.palette.primary.dark }}>${orderPreviewData[0]?.grand_total}</span>
                    </Typography>
                    </Stack>
                    {/* </Card> */}
                    <Divider />
                    <LabelStyleHead>Payments</LabelStyleHead>
                    <Stack direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 3, sm: 2 }}>
                    <Typography sx={{color:theme.palette.primary.main}}>Amount : ${orderPreviewData[0]?.grand_total}</Typography>
                      {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TableContainer component={Paper}>
                        <Table>
                           <TableHead>
                             <TableRow>
                               <TableCell>Sl.no.</TableCell> 
                                 <TableCell>Payment Mode</TableCell> 
                                 <TableCell>Amount : ${orderPreviewData[0]?.grand_total}</TableCell>
                               <TableCell>Action</TableCell>
                             </TableRow>
                           </TableHead>
                          <TableBody>
                            {rows.map((row, index) => (
                              <TableRow>
                                <TableCell width='15px'>1</TableCell>
                                <TableCell>
                                  <TextField
                                    value={row.paymentMode}
                                    onChange={(event) => handlePaymentModeChange(event, index)}
                                    variant="outlined"
                                    fullWidth
                                    autoComplete="off"
                                  />
                                  <Autocomplete
                                    select
                                    fullWidth
                                    id={`paymentMode`}
                                    options={paymentTypes}
                                    // value={selectedPaymentMode}
                                    value = {paymentTypes?.find((option) => option.id === selectedPaymentMode) || null}
                                    onChange={(event, newValue) => {setSelectedPaymentMode(newValue ? newValue?.id : null); setValidSelection(true);}}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Select Payment Mode"
                                        fullWidth
                                        error={!validSelection}
                                        helperText={!validSelection && "Please select a payment mode"}
                                      />
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    type="number"
                                    value={row.amount}
                                    onChange={(event) => handleAmountChange(event, index)}
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{ inputProps: { min: 0 } }}
                                  />
                                </TableCell>
                                <TableCell>
                                  {(index > 0) && <IconButton onClick={() => handleDeleteRow(index)}>
                                    <Delete />
                                  </IconButton>}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer> */}
                      <Autocomplete
                        select
                        fullWidth
                        id={`paymentMode`}
                        options={paymentTypes}
                        // value={selectedPaymentMode}
                        value = {paymentTypes?.find((option) => option.id === selectedPaymentMode) || null}
                        onChange={(event, newValue) => {setSelectedPaymentMode(newValue ? newValue?.id : null); setValidSelection(true);}}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Payment Mode"
                            fullWidth
                            error={!validSelection}
                            helperText={!validSelection && "Please select a payment mode"}
                          />
                        )}
                      />
                    </Stack>
                    {/* <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', gap:2 }}>
                      <Button variant="contained" color="primary" onClick={handleAddRow}>Add Payment</Button>
                      <Typography variant='body'>Balance Due : {orderPreviewData[0]?.grand_total-totalAmount}</Typography>
                    </Box> */}
                      
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap:2 }}>
                      <Button variant='contained' type='submit' onClick={handleReceivePayment}>Pay</Button>
                      <Button variant='outlined' onClick={()=>{setPaymentOpen(false)}}>Cancel</Button>
                    </Box>
                  </Stack> 

                </Stack>
              </Grid>
            </Grid>
          </Modal>
        )}

      </Container>
  );
}

const PrintFunction = forwardRef(({ invoiceDate, details, invoice }, ref) => {
  const theme = useTheme();

  return (
    <Stack ref={ref}>
      <Grid container spacing={3}>
      <Grid item xs={12} sm={6} sx={{ mb: 5, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Box component="img" alt="logo" src={COMPANY_LOGO} sx={{ height: 98, alignSelf: 'flex-start' }} />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ mb: 5, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          {/* <Typography variant="h1">Invoice from:</Typography> */}
          <Typography variant="h2">{COMPANY_NAME}</Typography>
          <Typography variant="h3">www.signwisesolutions.com</Typography>
          {/* <Typography variant="h3">
            1st Floor, Administrative block, Kinfra Integrated <br /> Industrial Park, Ottapalam, Palakkad, Kerala, India
          </Typography>
          <Typography variant="h3">Telephone: +91-9496351674</Typography> */}
        </Grid>
      </Grid>

      <Grid container spacing={2}>

        <Grid id="total1"  item xs={12} sm={6} >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>        
        <Box id="order" style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between'  }}>
          <Typography id="discount1" variant="h3" style={{ textAlign:'left'}}>Order date:</Typography>
          <Typography variant="h3">{invoiceDate}</Typography>
        </Box>
        <Box id="title" style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between'  }}>
          <Typography id="discount1" variant="h3" style={{ textAlign:'left'}}>Order Name:</Typography>
          <Typography variant="h3">{details?.order_name}</Typography>
        </Box>
        <Box id="orderID" style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between'  }}>
          <Typography id="discount" variant="h3" style={{ textAlign:'left'}}>Order ID:</Typography>
          <Typography variant="h3">{details?.order_id}</Typography>
        </Box>
        <Box id="to" style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between' }}>
          <Typography id="totalmain1" variant="h3" >Vendor Name:</Typography>
          <Typography variant="h3" style={{textAlign:'right'}}>{details?.vendor_name}</Typography>
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between' }}>
          <Typography id="totalmain1" variant="h3" >Phone:</Typography>
          <Typography variant="h3" style={{textAlign:'right'}}>{details?.vendor_phone}</Typography>
        </Box>
            
          </Box>
        </Grid>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 420 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='center'>#</TableCell>
                  <TableCell align='center'>PRODUCT</TableCell>
                  <TableCell align='center'>UNIT PRICE</TableCell>
                  <TableCell align='center'>QTY</TableCell>
                  <TableCell align='center'>SUB TOTAL</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoice?.map((val,index) => {
                  const { id, name, unit_cost, actual_qty, actual_total_cost,} = val
                  return (
                    <TableRow key={id} sx={{
                      borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                      '& .productName': {
                        fontWeight: 'bold',
                      },
                    }}>
                      <TableCell align='center' variant="h4">{index+1}</TableCell>
                      <TableCell align='center' variant="h4" className="productName">{name}</TableCell>
                      <TableCell align='center' variant="h4">{unit_cost}</TableCell>
                      <TableCell align='center' variant="h4">{actual_qty}</TableCell>
                      <TableCell align='center' variant="h4">{actual_total_cost}</TableCell>
                    </TableRow>
                    )
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Grid id="total"  item xs={12} sm={6} >
          <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Box style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between' }}>
              <Typography id="subtotal" variant="h3" >Sub Total</Typography>
              <Typography variant="h3" style={{textAlign:'right'}}>${details?.sub_total}</Typography>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between'  }}>
              <Typography id="discount" variant="h3" style={{ textAlign:'left'}}>Tax</Typography>
              <Typography variant="h3">{details?.tax}%</Typography>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between' }}>
              <Typography id="totalmain" variant="h3" >Grand Total</Typography>
              <Typography variant="h3" style={{textAlign:'right'}}>${details?.grand_total}</Typography>
            </Box>
          </Box>
        </Grid>
                      
      </Grid>
    </Stack>
  )
})