import { Avatar, Box, Button, Card, CardHeader, Grid, IconButton, InputAdornment, OutlinedInput, Stack, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import Scrollbar from './Scrollbar';
import MyAvatar from './MyAvatar';
import { PATH_CALL } from 'src/routes/paths';
// import Twilio from 'twilio-client';
import { Device } from '@twilio/voice-sdk';
import { Worker } from 'twilio-taskrouter';
const TaskRouter = require("twilio-taskrouter");
import axios from 'axios';
import { REST_API, REST_API_END_POINT,COMPANY_LOGO } from 'src/constants/Defaultvalues';
import SocketClient from 'src/Helpers/SocketClient';
import ConfirmationPopup from './confirmationPopup';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import callEnd from '@iconify/icons-ic/call-end';
import call from '@iconify/icons-ic/call';
import HoldCallIcon from '@iconify/icons-ic/pause';
import MuteIcon from '@iconify/icons-ic/mic-off';
import { BackspaceOutlined,CallEnd,Dialpad } from '@material-ui/icons';
// import holdCall from '@iconify/icons-ic/pause-circle-fill';
// import holdCall from '@iconify/icons-/pause-circle-fill';


import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import LoadingScreen from './LoadingScreen';


const Data = [
  {
    id: 0,
    name: 'Person Name',
    to: '+919496351674',
    direction: 'Incoming',
    duration: '00:18',
  },
  {
    id: 1,
    name: 'Person Name',
    to: '+919496351674',
    direction: 'Incoming',
    duration: '00:18',
  },
  {
    id: 2,
    name: 'Person Name',
    to: '+919496351674',
    direction: 'Incoming',
    duration: '00:18',
  },
  {
    id: 3,
    name: 'Person Name',
    to: '+919496351674',
    direction: 'Incoming',
    duration: '00:18',
  },
  {
    id: 4,
    name: 'Person Name',
    to: '+919496351674',
    direction: 'Incoming',
    duration: '00:18',
  },
]

const icons = [
  { icon: HoldCallIcon, color: '#aeb1b5' },
  { icon: MuteIcon, color: '#aeb1b5' },
];

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 240, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

function MinimizablePopup() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [isMute, setIsMute] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [worker, setWorker] = useState(null);
  const [callInstance, setCallInstance] = useState(null);
  const [childCallSid, setChildCallSid] = useState(null);
  const [incomingNumber, setIncomingNumber] = useState("Incoming Call");
  const [incomingName, setIncomingName] = useState('');
  const [clientprofilePic,setClientprofilePic] = useState('avatar');
  const [parentCallData, setParentCallData] = useState();
  const [isMicAllowed, setIsMicAllowed] = useState(null);
  const [component, setComponent] = useState(null);
  const [button, setButton] = useState('Offline');
  const [callStatus, setCallStatus] = useState('');
  const [userData, setUserData] = useState([]);
  const [reservationObject, setReservationObject] = useState(null);
  const [isNormalCall, setIsNormalCall] = useState(false);
  const [showAgentActions, setShowAgentActions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inCallInterval, setInCallInterval] = useState(null);
  const [inCallStartTime, setInCallStartTime] = useState(0);
  const [allConferencePhones, setAllConferencePhones] = useState([]);
  const [input, setInput] = useState('');
  // const [openDialpad, setOpenDialpad] = useState(true);
  // const [audio] = useState(new Audio('/static/callertune.mp3'));
  const [playAudio,setPlayAudio] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  const [iconStates, setIconStates] = useState(icons.map(() => ({ backgroundColor: '#aeb1b5' })));

  const handleButtonClick = (index) => {
    setIconStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = {
        backgroundColor: newStates[index].backgroundColor === '#aeb1b5' ? '#4287f5' : '#aeb1b5',
      };
      return newStates;
    });
  };

  const user = JSON.parse(localStorage.getItem('user'))

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  // useEffect(()=>{
  //   openNewTab(PATH_CALL.root)
  //   return ()=>window.close(PATH_CALL.root)
  // },[])
  // const openNewTab = (url) => {
  //   const windowFeatures = 'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
  //   window.open(url, '_blank','width=500,height=1000');
  // };

  useEffect(() => {
      try {
        axios.post(`${REST_API}fetch-worker-token`,{
            user: user.email,
            workerSid: user.worker_sid,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
        }).then((response) => {
          console.log(response.data.worker);
          initializeTaskRouter(response.data.clientToken,response.data.workerToken,response.data.worker);
          // localStorage.setItem('worker',JSON.stringify(response.data.worker))
          setButton(response.data.worker.activityName)
          setWorker(response.data.worker);
      })
      } catch (error) {
        console.error('Error Fetching Worker Token:', error);
      }

  }, []);

  const fetchUserData = async () => {
    setLoading(true)
    try{
      const response = await axios.post(`${REST_API}getUserById`,{userId:user.user_id})
      if(response.data){
        let userDetails = response.data;
        // console.log("fetchUserData----",userDetails[0]);
        setUserData(userDetails[0]);
      }
    }
    catch(error){
      console.log(error);
    } finally {
      setLoading(false)
    }
  }

  useEffect(()=>{
    fetchUserData();
  },[])

  

  useEffect(() => {
    let socketClient = new SocketClient();
    socketClient.on('customEvent', (data) => {
      console.log('Message from callcenter:', data);
    });
  },[])

  useEffect(() => {
    if (inCallInterval == null && accepted) {
      setInCallInterval(setInterval(function () {
        setInCallStartTime(prevTime => prevTime + 1);
      }, 1000));
    }
    return () => {
      if (inCallInterval) {
        clearInterval(inCallInterval);
      }
    };
  }, [inCallInterval, accepted]);

  const formatTime = (time) => {
    let finalTime = '';

    const hours = Math.floor(time / 3600);
    if (hours > 0) {
      finalTime += hours > 9 ? hours.toString() : '0' + hours.toString();
      finalTime += ":";
    }

    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    finalTime += minutes > 9 ? minutes.toString() : '0' + minutes.toString();
    finalTime += ":";
    finalTime += seconds > 9 ? seconds.toString() : '0' + seconds.toString();

    return finalTime;
  };
  
  const goAvailable = async(callStatus,activityName)=>{
    try {
      axios.post(`${REST_API}update-available-for-call`,{
        userId: user.user_id,
        availableForCall: callStatus,
        workerSid: user.worker_sid,
        activityName,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
      }).then((response) => {
        if(response.data.status === 1){
          enqueueSnackbar(response.data.message, { variant: 'success' });
          setButton(activityName)
          // Update worker's activity to Idle using Twilio library
          // device.update({ ActivitySid: activitySid }, function (error, updatedWorker) {
          //   if (error) {
          //     console.error(error.code);
          //     console.error(error.message);
          //   } else {
          //     console.log('Worker activity updated to Idle:', updatedWorker);
          //   }
          // });
        }else if(response.data.status === 0){
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
    })
    } catch (error) {
      console.error('Error Fetching Worker Token:', error);
    }
  }

  // console.log("parentCallData ////////////: ",parentCallData);
  // console.log("callInstance //////////////////: ",callInstance);

  const handleAddCallDetails = (parentData,childId) => {
    axios.post(`${REST_API}twilio-calls/add-incoming-call-details`,{parentCall:parentData,childSid:childId})
    .then((response)=>{
      console.log("Add Incoming call : ",response.data);
      if(response.data.status === 1){
        setParentCallData(response.data.parentCall);
        setChildCallSid(response.data.childSid);
      }
    })
  }

  const handleUpdateCallDetails = () => {
    console.log("parentCallData - add details : ",parentCallData);
    console.log("callInstance - add details : ",callInstance);
    console.log("childCallSid - add details : ",childCallSid);

    axios.post(`${REST_API}twilio-calls/add-incoming-call-details`,{parentCall:parentCallData,childSid:childCallSid})
    .then((response)=>{
      console.log("update Incoming call : ",response.data);
    })
  }

  const fetchCallData = (call) => {
    let parentData;
    let childId = call;
    axios.post(`${REST_API}twilio-calls/get-incoming-details`,{callSid:childId})
    .then((response)=>{
      const phoneNumber = response.data.number;
      console.log("Incoming number : ",response.data);
      parentData = response.data.details
      setIncomingNumber(response.data.number)
      setParentCallData(response.data.details)
      handleAddCallDetails(parentData,childId);
      if(phoneNumber){
        axios.post(`${REST_API}twilio-calls/scan-crm-phone-number`,{phoneNumber})
        .then((response)=>{
          if (response.data.status === 1){
            console.log("Incoming name : ",response.data.existenceResult);
            const resultData = response.data.existenceResult;
            if(resultData){
              const name = resultData.split(',')[2];
              const profile_pic = resultData.split(',')[3];
              setIncomingName(name);
              setClientprofilePic(profile_pic);
            }
          }
          else{
            setIncomingName('Unknown Number');
          }
        })
      }
    })
  }


  const initializeTaskRouter = async (clientToken,workerToken,worker) => {
    try {

      navigator.mediaDevices.getUserMedia({ audio: true })
      .then(function (stream) {
        console.log('You let me use your mic!');
        setIsMicAllowed(true);
      })
      .catch(function (err) {
        console.log('No mic for you!');
        enqueueSnackbar('Please connect your microphone', { variant: 'error' });
        setIsMicAllowed(false);
      });

      const isConfirmed = await showConfirmationDialog();

      if (isConfirmed) {
        console.log('isConfirmed');
        if (worker.activityName !== 'Available') {
          goAvailable(1,'Available');
        }

        // try {
        //   const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        //   console.log('You let me use your mic!', stream);
        // } catch (err) {
        //   console.log('No mic for you!', err);
        //   setTimeout(() => {
        //     alert('Please connect your microphone');
        //   }, 100);
        // }
      } else {
        window.close();
      }

      console.log('Initializing...',workerToken);

      const WORKER = new Worker(workerToken)

        console.log('wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww',WORKER);

        WORKER.on("ready", (readyAlice) => {
          console.log(`Worker ${readyAlice.sid} is now ready for work`);
        });

        WORKER.on("reservationCreated", async(reservation) => {
          console.log(
            `Reservation ${reservation.sid} has been created for ${WORKER.sid}`,'data :',reservation
          );
          // reservation.accept()
          // refreshWorkerUI(WORKER, "Incoming Reservation")
          // setReservationObject(reservation)
          // setIsOpen(true)

        //   axios.post(`${REST_API}accept-reservation`,{
        //     taskSid: reservation.task.sid,
        //     reservationSid: reservation.sid,
        //     },
        //     {
        //       headers: {
        //         'Content-Type': 'application/json',
        //       },
        //   }).then((response) => {
            
        // })

        

          reservation.on("accepted", (acceptedReservation) => {
            console.log("acceptedReservation",acceptedReservation);
            console.log(`Reservation ${acceptedReservation.sid} was accepted.`);
          });

          reservation.on("rejected", (rejectedReservation) => {
            console.log(`Reservation ${rejectedReservation.sid} was rejected.`);
          });

          reservation.on("timeout", (rejectedReservation) => {
            console.log(`Reservation ${rejectedReservation.sid} was timeout.`);
          });

          reservation.on("canceled", (rejectedReservation) => {
            console.log(`Reservation ${rejectedReservation.sid} was canceled.`);
            setReservationObject(null)
          });

        //   reservation.accept()
        //   .then((acceptedReservation) => {
        //     console.log(`Reservation status is ${acceptedReservation.status}`);
        //   })
        //   .catch((err) => {
        //     console.log(`Error: ${err}`);
        //   });

        // var options = {
        //   "From": "+1233753252",  // CC's phone number
        //   "To": `client:${user.worker_sid}`,
        //   "PostWorkActivitySid": "WA2e4dd266d132ea32861e3aeda6f3ecff",
        //   "Timeout": "120",
        //   "ConferenceStatusCallback": `https://5f83-117-251-18-23.ngrok-free.app/conference-status-callback`,
        //   "ConferenceStatusCallbackEvent": "start,end,join,leave",
        //   "EndConferenceOnExit": "true"

        // };
        // if(reservation){
        //   await reservation.conference(null, null, null, null, null,
        //     // function (error, conferenceReservation) {
        //     //   console.log('reservation.conference',conferenceReservation);
        //     //   if (error) {
        //     //     console.log("conference error",error.code);
        //     //     console.log("conference error",error.message);
        //     //   }
        //     // },
        //     options
        //   )
        //   // reservation.conference();
        //   console.log("Conference initiated!");
        // }
        
            
        })

        WORKER.on('accepted', (reservation) => {
          console.log('reservation.accepted 2',reservation);
        });

        WORKER.on('reservationAccepted', (reservation) => {
          console.log('reservation.accepted 3',reservation);
        });

        WORKER.on('reservation.accepted', (reservation) => {
          console.log('reservation.accepted 4',reservation);
        });

      const deviceOptions = {
        logLevel:1,
        edge: 'ashburn',
        allowIncomingWhileBusy: true,
        enableImprovedSignalingErrorPrecision: true,
        // tokenRefreshMs: 30000,
      }
      const device = new Device(clientToken,deviceOptions);


      device.on(Device.EventName.Registered, (dev) => {
        console.log('The device is ready to receive incoming calls ','Device.isSupported: ',Device.isSupported,'device.state: ',device.state,'device.isBusy: ',device.isBusy)
      });

      // Incoming calls
      device.on(Device.EventName.Incoming, call =>{
        console.log('device incoming...........................',call.parameters.CallSid);
        if(call.parameters.CallSid) fetchCallData(call.parameters.CallSid)
        setIsOpen(true)
        setCallInstance(call)

        call.on('disconnect', ()=>{
          console.log('Call hangup2');
          handleUpdateCallDetails();
          setIsOpen(false)
          setAccepted(false)
          clearInterval(inCallInterval);
          setInCallInterval(null)
          setInCallStartTime(0)
          setIncomingNumber("Incoming Call")
          setIncomingName('')
        })
        call.on('cancel', ()=>{
          console.log('Call cancel');
          handleUpdateCallDetails();
          setIsOpen(false)
          setAccepted(false)
          setInCallInterval(null)
          setInCallStartTime(0)
          clearInterval(inCallInterval);
          setIncomingNumber("Incoming Call")
          setIncomingName('')
        })
        call.on('reject', (call) => {
          console.log('The call was rejected.',call);
         });

      });

      device.on('tokenWillExpire', () => {
        console.log('tokenWillExpire in 30 seconds');
        // return getTokenViaAjax().then(token => dev.updateToken(token));
      });

      device.on("connect", (connection) => {
        console.log("Call connected",connection);
        // setConnection(connection);
        // setUserState(USER_STATE.ON_CALL);
      });

      device.audio.incoming(true);
      device.audio.outgoing(true);

      await device.register().then((res) => {
        console.log('Device registered successfully');
        console.log('device :',device);
      }).catch((error) => {
        console.error('Error registering device', error);
      });

      // registerTaskRouterCallbacks(device);

    } catch (error) {
      console.error('Error initializing TaskRouter:', error);
    }
  };


  useEffect(() => {
    const handleBeforeUnload = () => {
      goAvailable(0,'Offline')

      // const confirmationMessage = 'Are you sure you want to leave?';
      // (event || window.Event).returnValue = confirmationMessage;
      // return confirmationMessage;
    };

    const handleUnload = () => {
      // Perform any additional cleanup if needed
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, []);

  const showConfirmationDialog = () => {
    return new Promise((resolve) => {
      setComponent(<ConfirmationPopup open={true} handleClick={()=>{
        setComponent(null)
        resolve(true)
      }} message={'Do you want to enter into the call centre?'} handleClose={()=>window.close()}/>)
    });
  };

  const registerTaskRouterCallbacks = async(device) => {
    // let worker = JSON.parse(localStorage.getItem('worker'))
    console.log('device: ',device);

    device.on('registered', device => {
      console.log('The device is ready to receive incoming calls.')
    });

    // device.register();

    // device.on('tokenWillExpire', () => {
    //   console.log('dfddddddddddddddd');
    //   const token = getNewTokenViaAjax();
    //   device.updateToken(token);
    // });

    device.on('ready', function (device) {
      console.log("Successfully registered as: " + device.friendlyName);
      
      if (device.attributes.skills && device.attributes.skills.includes('manager')) {
        console.log("User is a manager.");
        // Handle manager logic as needed
      } else {
        console.log("User is an agent.");
        // Handle agent logic as needed
      }
      
      if (device.attributes.skills) {
        console.log("Skills: " + device.attributes.skills.join(', '));
      }
      
      if (device.attributes.languages) {
        console.log("Languages: " + device.attributes.languages.join(', '));
      }
      
      console.log("Current activity is: " + device.activityName);
      // Call refreshWorkerUI(worker) or perform actions accordingly
    });

    // Outgoing calls
    // const call = await device.connect({
    //   rtcConfiguration: {
    //     sdpSemantics: 'unified-plan'
    //   }
    //   // Other options
    // });

    // Incoming calls
    device.on('incoming', call => {
      call.accept({
        rtcConfiguration: {
          sdpSemantics: 'unified-plan'
        }
        // Other options
      });
    });

  };

  const refreshWorkerUI = (worker, activityOverride = null) => {
    let activityName = activityOverride || worker.activity.name;

    console.log("Worker activity: " + activityName);

    let buttons = {
      'online': false,
      'offline': false,
      'mute': false,
      'unmute': false,
      'escalate': false,
      'accept': false,
      'reject': false,
      'hangup': false,
      'addlead': false,
      'viewprofile': false
    }

    switch (activityName) {
      case "Available":
        console.log("Worker Available");
        buttons['offline'] = true;
        break;
      case "Offline":
        console.log("Worker Offiline");
        buttons['online'] = true;
        break;
      case "Incoming Reservation":
        console.log("Incoming Reservation");
        setIsNormalCall(false);
        buttons['accept'] = true;
        buttons['reject'] = true;
        navigator.mediaDevices.getUserMedia({ audio: true })
          .then(function (stream) {
            console.log('You let me use your mic!')
          })
          .catch(function (err) {
            console.log('No mic for you!')
          });
          setIsOpen(true)
        // Assuming you have jQuery available
        // $('#incoming_call_init').show();
        // $('#twilio_action_content').show();
        // document.getElementById('audio').play();
        // setTimeout(function () {
        //   scanCrmPhoneNumber(buttons, reservationObject);
        // }, 1500);
        // localStorage.setItem("ung-incoming-call", true);
        break;
      case "In a Call":
        console.log("In a Call");
        setShowAgentActions(true);
        document.getElementById('audio').pause();
        document.getElementById('audio').currentTime = 0;
        if (!isManager) {
          // buttons['escalate'] = true;
        }
        buttons['mute'] = true;
        buttons['unmute'] = false;
        buttons['hangup'] = true;
        $('#twilio_action_content').hide();
        $('#button-hangup-wrapper').show();
        $('.twilio-action-btns').show();
        $('#twilio_call_content').show();
        $('.call_timing').show();

        break;
      case "WrapUp":
        console.log("WrapUp");
        buttons['offline'] = true;
        buttons['online'] = true;
        $('#incoming_call_init').hide();
        $('#button-hangup-wrapper').hide();
        $('.twilio-action-btns').hide();
        setAllConferencePhones([]);
        $('#twilio_call_btn_hold').removeClass('twilio-active-btn');
        setIsNormalCall(false);
        break;
      case "Reject":
        console.log("Call rejected by worker");
        document.getElementById('audio').pause();
        document.getElementById('audio').currentTime = 0;
        buttons['offline'] = true;
        $('#incoming_call_init').hide();
        $('#button-hangup-wrapper').hide();
        $('.twilio-action-btns').hide();
        setAllConferencePhones([]);
        $('#twilio_call_btn_hold').removeClass('twilio-active-btn');
        setIsNormalCall(false);
        break;
    }
    Object.keys(buttons).forEach(function (button) {
      document.getElementById("btn_" + button).style.display = (buttons[button] ? "inline-block" : "none");
    });

    let available = worker.available;
    document.getElementById("worker_status").innerText = activityName;
    if (available === true) {
      document.getElementById("worker_status").style.color = "#00BB00";
    } else {
      document.getElementById("worker_status").style.color = "#BB0000";
    }
  }

  const handleKeyPress = (value) => {
    const limit = 18;
    const enteredNumber = input + value
    if (enteredNumber?.length <= limit) {
      setInput(enteredNumber);
    }
  };
  const handleBackSpace = () => {
    setInput(pv=>pv.slice(0, -1));
  };

  return (
    <Grid container spacing={3} py={10} px={2} sx={{alignItems:'center',justifyContent:'center'}}>
      {loading ? <LoadingScreen /> :
        <>
        <Grid item xs={12} sm={6}>
          <Stack spacing={2}>
          <Card sx={{ p: 3 }}>
          <Stack direction={'row'}>
            <Box>
            <Avatar
              alt={(userData?.first_name) ? `${userData?.first_name}  ${userData?.last_name}` : userData?.company_name }
              src={(userData?.profile_pic) ? userData?.profile_pic : COMPANY_LOGO}
              sx={{
                // mx: 'auto',
                borderWidth: 2,
                borderStyle: 'solid',
                borderColor: 'common.white',
                width: { xs:96 },
                height: { xs:96 }
              }}
            />
              {/* <MyAvatar
                sx={{
                  // mx: 'auto',
                  borderWidth: 2,
                  borderStyle: 'solid',
                  borderColor: 'common.white',
                  width: { xs: 60, md: 96 },
                  height: { xs: 60, md: 96 }
                }}
              /> */}
              <Box sx={{
                height:'20px',
                width:'20px',
                backgroundColor: (button === 'Available') ? 'green' : 'red',
                position:'relative',
                bottom:15,
                left:65,
                borderRadius:'50%'
              }}/>
            <Typography sx={{ opacity: 0.72,color: (button == 'Offline') ? 'red' : 'green' }}>{button}</Typography>
            </Box>

            <Box
              sx={{
                ml: { md: 3 },
                mt: { xs: 1, md: 0 },
                color: 'common.white',
                textAlign: { xs: 'center', md: 'left' }
              }}
            >
              {/* (userData.user_type==2) */}
              <Typography variant="h4">{(userData?.first_name) ? `${userData?.first_name}  ${userData?.last_name}`: userData?.company_name }</Typography>
              <Typography sx={{ opacity: 0.72 }}>{userData?.department_name}</Typography>
            </Box>
          </Stack>
          <Box
            sx={{
              mt: 3,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            {button === 'Available' ? <Button onClick={()=> goAvailable(0,'Offline')} variant="contained" color='error'>Go Offline</Button> :
            button === 'Offline' ? <Button onClick={()=> goAvailable(1,'Available')} variant="contained" color='success'>Go Available</Button> : ''}
          </Box>
          </Card>
          {isOpen && <Card sx={{ p: 3 }}>
          <Stack direction={'column'} sx={{alignItems:'center', justifyContent:'center'}} spacing={2}>
            <Box sx={{alignItems: 'center', justifyContent:'center', mt: 2 }}>
            <Avatar
              alt={''}
              src={clientprofilePic+'.jpg'} 
              sx={{
                // mx: 'auto',
                borderWidth: 2,
                borderStyle: 'solid',
                borderColor: 'common.white',
                width: { xs: 50, md: 70 },
                height: { xs: 50, md: 70 }
              }}
            />
              {/* <MyAvatar
                sx={{
                  // mx: 'auto',
                  borderWidth: 2,
                  borderStyle: 'solid',
                  borderColor: 'common.white',
                  width: { xs: 60, md: 96 },
                  height: { xs: 60, md: 96 }
                }}
              /> */}
            </Box>
            <Box
              sx={{
                ml: { md: 3 },
                mt: { xs: 1, md: 0 },
                color: 'common.white',
                textAlign: { xs: 'center', md: 'center' }
              }}
            >
              <Typography variant="h6">{incomingName}</Typography>
              <Typography variant="h6">{reservationObject?.task.attributes.from || incomingNumber}</Typography>
              {accepted && <Typography variant="body">{formatTime(inCallStartTime)}</Typography>}
            </Box>
            <Box
              sx={{
                ml: { md: 3 },
                mt: { xs: 1, md: 0 },
                color: 'common.white',
                textAlign: { xs: 'center', md: 'center' }
              }}
            >
              {/* <Typography variant="body">{'callStatus'}</Typography> */}
            </Box>
          </Stack>
          {/* <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Stack direction={'row'} spacing={2}>
                <IconButton
                  sx={{ backgroundColor: '#aeb1b512', marginTop: 5}}
                  onClick={() => {
                    setOpenDialpad(!openDialpad)
                  }}
                  >
                  <Dialpad/>
              </IconButton>
              </Stack>
          </Box> */}
          <Box
            sx={{
              mb: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Box sx={{ mr: 3,}}>
              {!accepted ? <IconButton onClick={()=>{callInstance?.accept({
                  rtcConfiguration: {
                    sdpSemantics: 'unified-plan'
                  }
                })
                setAccepted(true)
                handleUpdateCallDetails();
                }} sx={{backgroundColor:"green", marginTop:5}}>
                <Icon icon={call} size={50} color='#ffffff'/>
              </IconButton> : 
                <IconButton
                  sx={{ backgroundColor: isMute  ? '#4287f5' : '#aeb1b5', marginTop: 5}}
                  onClick={() => {
                    callInstance.mute(!isMute)
                    setIsMute(!isMute)
                  }}
                  >
                  <Icon icon={MuteIcon} size={50} color='#ffffff'/>
              </IconButton>}
            </Box>
            {accepted ? <IconButton onClick={()=>{
              handleUpdateCallDetails();
              callInstance?.disconnect()
              setIsOpen(false)
              }} sx={{backgroundColor:"red", marginTop:5}}>
              <Icon icon={callEnd} size={50} color='#ffffff'/>
            </IconButton> : 
            <IconButton onClick={()=>{
              handleUpdateCallDetails();
              callInstance?.reject()
              setIsOpen(false)
              }} sx={{backgroundColor:"red", marginTop:5}}>
            <Icon icon={callEnd} size={50} color='#ffffff'/>
          </IconButton>}
          </Box>

          {/* <Box
            sx={{
              mt: 3,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
          {icons?.map((iconObj, index) => (
              <IconButton
                key={index}
                sx={{
                  backgroundColor: iconStates[index].backgroundColor,
                  marginTop: 5,
                  marginRight: 5,
                }}
                onClick={() => handleButtonClick(index)}
              >
                <iconObj.icon size={50} color='#ffffff' />
              </IconButton>
            ))} 
        </Box> */}

          </Card>}
        </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card sx={{  width:{xs:'330px',sm:'350px',md:'400px'}, py: 3, px: {xs:5,sm:7,md:10}, justifyContent:'center', alignItems:'center' }}>
            <Box sx={{width: '97%', py: 1, pl: 2,pr: 1, mb: 2, justifyContent:'space-between',alignItems:'center', display: 'flex',border:'0.2px solid #1ccaff42', borderRadius: 1}}>
              <Typography color={theme.palette.primary.main}>+{input}</Typography>
              <BackspaceOutlined sx={{ height:17,width:17,cursor:'pointer', color: theme.palette.primary.main }} onClick={handleBackSpace}/>
            </Box>
              <Grid container spacing={1}>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#'].map((value) => (
                <Grid item xs={4} key={value}>
                  <Button
                    variant="outlined"
                    sx={{borderRadius:'50% 25%'}}
                    // fullWidth
                    onClick={() => handleKeyPress(value)}
                  >
                    {value}
                  </Button>
                </Grid>
              ))}
            </Grid>
            <Box sx={{width: { xs: '100%', md: '90%' }, mb: 2, justifyContent:'center', display: 'flex', }}>
            <IconButton disabled={input ? false : true} onClick={()=>{
                  window.open(`${PATH_CALL.root}?phone=${input}&name=${''}&dial=${true}` , '_blank', 'width=500,height=1000')
                  setInput('')
                }} sx={{backgroundColor:"green", marginTop:5}}>
                <Icon icon={call} size={50} color='#ffffff'/>
              </IconButton>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <Card>
            {/* <Box 
            sx={{
              mt: 3,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <SearchStyle
            value={filterName}
            onChange={handleFilterByName}
            placeholder="Search ..."
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
          </Box>
            <Scrollbar>
              <Box minHeight={'167px'}>
                <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
                  {[].map((item) => (
                  <Stack direction="row" spacing={2}>
                    <Avatar alt={item.name} src={''} />
              
                    <Box sx={{ flexGrow: 1, minWidth: 200 }}>
                      <Typography variant='subtitle2' sx={{ color: 'text.primary',  }}>
                        {item.name}
                      </Typography>
              
                      <Stack direction="row">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          {item.to}
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>
                  ))}
                </Stack>
              </Box>
            </Scrollbar> */}
            {component}
          </Card>
        </Grid> 
        </>
      }
    </Grid>
  )
}

export default MinimizablePopup;

