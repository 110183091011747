import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Stack, AppBar, Toolbar, IconButton,Button } from '@material-ui/core';
// components
import { MHidden } from '../../components/@material-extend';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';

import LanguagePopover from './LanguagePopover';
import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
import SettingFullscreen from 'src/components/settings/SettingFullscreen';
import AIChatbot from './AIChatbot';
import ChatbotTestBackup from 'src/components/_dashboard/chatbot/ChatbotTestBackup.js';
import Typography from 'src/theme/overrides/Typography';
import { Link, useNavigate } from 'react-router-dom';
import { Theme } from '@fullcalendar/react';
import useAuth from 'src/hooks/useAuth';
import { PATH_DASHBOARD } from 'src/routes/paths';

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar, isCompact }) {
  const { loginWithUserId } = useAuth();
  let user = JSON.parse(window.localStorage.getItem('user'))
  let user_id = localStorage.getItem('user_id')
  let user_type = localStorage.getItem('user_type')
  let admin = false;

  // ----------------------------------------------------------------------

  const DRAWER_WIDTH = isCompact ? 80 : 280;
  const APPBAR_MOBILE = 64;
  const APPBAR_DESKTOP = 92;

  const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
    }
  }));

  const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
      minHeight: APPBAR_DESKTOP,
      padding: theme.spacing(0, 5)
    }
  }));

  // ----------------------------------------------------------------------

  const navigate=useNavigate()
  if (user_id != user?.user_id) {
    admin = true;
  }
  const loginAsUser = () => {
  
    console.log("user_id$$$$$$$$$$$$$$$^^^^^^^^^^^^^^",user_id);
    console.log("user$$$$$$$$$$$$$$$^^^^^^^^^^^^^^",user);
    loginWithUserId(1, user_id)
    if(user?.isVendor){
      navigate(PATH_DASHBOARD.client.vendors,{state:true})
    }else if (user?.team_id) {
      navigate(PATH_DASHBOARD.callCenter.teamaccount, { state: { data: user?.team_id, isTeam: true } })
    }else{
      navigate(PATH_DASHBOARD.client.cards,{state:true})
    }
  } 
  console.log("$$$$$$$$$$$$$$$^^^^^^^^^^^^^^",user);
  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        {/* <Searchbar /> */}
        {user && admin && (

          <>
            <h2> Logged in as{' '}
              {user != null ? user.first_name : ""}</h2>
                       
         
          <Button
          variant="outlined"
          type="submit"
          // component={RouterLink}
          // to={PATH_DASHBOARD.client.cards}
          onClick={loginAsUser}
          sx={{ alignItems: 'flex-end', mb: 4,mt:4,ml:2}}

        >
          Switch to Admin
        </Button>
        </>
        )}
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <LanguagePopover /> */}

          <ChatbotTestBackup />

          {/* <AIChatbot/> */}
          <SettingFullscreen />
          <NotificationsPopover />

          {/* <ContactsPopover /> */}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
