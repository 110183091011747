import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { paramCase } from 'change-case';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { BlockOutlined, CopyAll, TaskAltOutlined } from '@material-ui/icons';
// material
import { useTheme } from '@material-ui/core/styles';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, } from '@material-ui/core';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
import { useSnackbar } from 'notistack';
import ConfirmationPopup from '../../official-request/ConfirmationPopup'
// ----------------------------------------------------------------------

MaterialInventoryMenu.propTypes = {
  onDelete: PropTypes.func,
  userName: PropTypes.string,
  id: PropTypes.number,
  status: PropTypes.number
};

export default function MaterialInventoryMenu({ onDelete, userName, id,noEdit, status, fromFinishedProducts,fromInventoryAudit, onRefresh, onEdit ,setRefresh}) {
  const ref = useRef(null);
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false);
  const [openDeletePop,setOpenDeletePop] =useState(false)
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const userData = JSON.parse(localStorage.getItem('user'))
  const explodedPermissions =userData?.exploded_permissions

  const changeStatus = async (prodId, prodStatus) => {
    try {
      let id = prodId;
      let status = prodStatus;
      let response;
      // console.log("id-----", id, " status-----------", status)
      if(fromFinishedProducts){
        response = await axios.post(`${REST_API_END_POINT}product/delete-finished-product`,{id})
      }
      else{
       response = await axios.post((`${REST_API_END_POINT}product/delete-product?id=${id}&status=${status}`),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        })
      }
      // console.log("response-----------------", response.data);
      if (response.data.status === 1 || response.data.status === 2 || response.data.status === 4) {
        enqueueSnackbar(response.data.message, { variant: 'success' });
      }
      else if (response.data.status === 0 || response.data.status === 3 || response.data.status === 5 || response.data.status === 500) {
        enqueueSnackbar(response.data.message, { variant: 'error' });
      }
      else {
        console.log("Error.........!");
        enqueueSnackbar("Error.........!", { variant: 'error' });
      }
      fromFinishedProducts ? onRefresh(id) : navigate(PATH_DASHBOARD.products.material, { state: { data: true } });
    }
    catch (error) {
      console.log("Delete error : ", error);
    }
  }

  const handleMenuItemClick = async (id, newStatus) => {
    setIsOpen(false)
    setOpenDeletePop(false);
    await changeStatus(id, newStatus);
    if(!fromFinishedProducts){
      navigate(PATH_DASHBOARD.products.material);
    }
  }
  const handleDuplicate=async()=>{
    let res = await axios.post(`${REST_API_END_POINT}product/duplicate`,{
      id,
     })
      if(res.data.status ==1){
        enqueueSnackbar(res.data.message,{variant:'success'})
        setRefresh(true)
      }else if( res.data.status ==0){
        enqueueSnackbar(res.data.message,{variant:'success'})
        
      }else{
      enqueueSnackbar(res.data.message,{variant:'success'})
  
      }
    
   
    
   }
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {fromFinishedProducts && onEdit ? 
          !noEdit?<MenuItem
            
            onClick={()=>{onEdit(id);setIsOpen(false);}}
          >
            <ListItemIcon>
              <Icon icon={editFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>:""
          :
          <><MenuItem
            disabled={explodedPermissions?.some(permission => ['Edit Material Label'].includes(permission)) ? false : true}
            component={RouterLink}
            to={`${PATH_DASHBOARD.products.editMaterial}?id=${id}/edit`}
          >
            <ListItemIcon>
              <Icon icon={editFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
          <MenuItem
         
          onClick={()=>{handleDuplicate()}}
        >
          <ListItemIcon>
          <CopyAll icon={CopyAll} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Duplicate" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        </>
        }

        {fromFinishedProducts ? '' : (status === 1 ? (
          <MenuItem
          disabled={explodedPermissions?.some(permission => ['Activate/Deactivate Material Label'].includes(permission)) ? false : true}
          onClick={() => handleMenuItemClick(id, 0)}
          >
            <ListItemIcon>
              <BlockOutlined sx={{ width: 25, height: 25 }} />
            </ListItemIcon>
            <ListItemText primary="Deactivate" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        ) : (
          <MenuItem
          disabled={explodedPermissions?.some(permission => ['Activate/Deactivate Material Label'].includes(permission)) ? false : true}
          onClick={() => handleMenuItemClick(id, 1)}
          >
            <ListItemIcon>
              <TaskAltOutlined sx={{ width: 25, height: 25 }} />
            </ListItemIcon>
            <ListItemText primary="Activate" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        ))}
          {!fromInventoryAudit && <MenuItem
          disabled={explodedPermissions?.some(permission => ['Delete Material Label'].includes(permission)) ? false : true}
          onClick={()=>{setOpenDeletePop(true),setIsOpen(false)}}>
            <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>}
      </Menu>

    {/* Confirmation for deleting product  */}
    <ConfirmationPopup 
      open={openDeletePop}
      handleClose={()=>{setOpenDeletePop(false)}}
      message={`You want to delete ${userName} ! `}
      handleClick={()=>{handleMenuItemClick(id, 2)}}
    />
    </>
  );
}
