const empty = (data) => {
    if (!data) {
        return true
    }
    if (data === '0') {
        return true
    }
    if (typeof (data) === 'number' || typeof (data) === 'boolean') {
        return false;
    }
    if (typeof (data) === 'undefined' || data === null) {
        return true;
    }
    if (data == 'undefined' || data == null) {
        return true;
    }
    if (typeof (data.length) !== 'undefined') {
        return data.length === 0;
    }
    var count = 0;
    for (var i in data) {
        if (data.hasOwnProperty(i)) {
            count++;
        }
    }
    return count === 0;
}

const zeroPad = (input, length) => {
    return (Array(length + 1).join('0') + input).slice(-length);
}

const dateDiffMax = (diff) => {
    let dateDiff = ''
    let i = 1
    Object.entries(diff).map(([key, value]) => {
        if (i > 2) {
            return
        }
        if (value !== 0 && key !== 'firstDateWasLater') {
            dateDiff += value + ' ' + (value > 1 ? key : key.slice(0, -1)) + " "
            i++
        }
    })

    return dateDiff;
}

const arrayDiffAssoc = (oldData, newData) => {
    let updatedFields = {}
    Object.entries(newData).map(([key, value]) => {
        if (!oldData.hasOwnProperty(key)) {
            updatedFields.push(key)
        } else {
            if (oldData[key] != value) {
                updatedFields[key] = value
            }
        }
    })
    return updatedFields
}

const array_fill = (startIndex, num, mixedVal) => {
    let key
    const tmpArr = {}
    if (!isNaN(startIndex) && !isNaN(num)) {
        for (key = 0; key < num; key++) {
            tmpArr[(key + startIndex)] = mixedVal
        }
    }
    return tmpArr
}

const arrayColumn = (array, column) => {
    return array.map(item => item[column]);
}

const inArray = (needle, haystack) => {
    var length = haystack.length;
    for (var i = 0; i < length; i++) {
        if (haystack[i] == needle) return true;
    }
    return false;
}

const getAgoString = (startDate) => {
    const now = new Date();
    const diffInMilliseconds = now - startDate;
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    if (diffInSeconds < 5) {
        return 'Just now';
    } else if (diffInSeconds < 60) {
        return `${diffInSeconds} ${diffInSeconds === 1 ? 'second' : 'seconds'} ago`;
    } else if (diffInSeconds < 3600) {
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        return `${diffInMinutes} ${diffInMinutes === 1 ? 'minute' : 'minutes'} ago`;
    } else if (diffInSeconds < 86400) {
        const diffInHours = Math.floor(diffInSeconds / 3600);
        return `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'} ago`;
    } else if (diffInSeconds < 2592000) {
        const diffInDays = Math.floor(diffInSeconds / 86400);
        return `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
    } else if (diffInSeconds < 31536000) {
        const diffInMonths = Math.floor(diffInSeconds / 2592000);
        return `${diffInMonths} ${diffInMonths === 1 ? 'month' : 'months'} ago`;
    } else {
        const diffInYears = Math.floor(diffInSeconds / 31536000);
        return `${diffInYears} ${diffInYears === 1 ? 'year' : 'years'} ago`;
    }
}

module.exports = { empty, zeroPad, dateDiffMax, arrayDiffAssoc, array_fill, arrayColumn, inArray, getAgoString }